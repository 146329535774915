<template>
  <div class='start'>
    <!-- <template v-if='!user.emailVerified'> -->
    <template v-if='false'>
      <div class='nja'>
        <div class='prg'>Verification email has been sent to <span class='thw'>{{ user.email }}</span>.</div>
        <div class='prg'>Please check your mailbox to verify your account. If you have already verified, click Reload to continue.</div>
      </div>
      <div class='wor'>
        <button-element
          :disabled='reloading'
          @click='reload'>
          Reload
          <progress-element class='uiq'
            :active='reloading'/>
        </button-element>
      </div>
    </template>
    <template v-else>
      <div class='nja'>
        <div class='prg'>Are you ready to take Corporate Innovation Maturity Assessment to learn how your organization is doing on its corporate innovation journey?</div>
      </div>
      <div class='wor'>
        <router-link :to='{ name: "test" }'>
          <button-element>Start</button-element>
        </router-link>
      </div>
    </template>
    <flash-message-component
      ref='errorDialog'
      type='error'>
      Oops! Something went wrong. Please try again later.
    </flash-message-component>
  </div>
</template>

<script>
  import { computed, ref } from 'vue'
  import { useStore } from 'vuex'
  import FlashMessage from '@/components/flash-message/flash-message.vue'
  import store from '@/store'
  import { GET_USER, RELOAD_USER } from '@/store/action-types'

  export default {
    components: {
      'flash-message-component': FlashMessage
    },

    async beforeRouteEnter (to, from, next) {
      let user = await store.dispatch(GET_USER)
      if (!user) {
        next({ name: 'login' })
        return
      }
      next()
    },

    setup () {
      let store = useStore()
      let user = computed(() => store.state.user.user)
      let reloading = ref(false)
      let errorDialog = ref(null)

      let reload = async () => {
        try {
          reloading.value = true
          await store.dispatch(RELOAD_USER)
        } catch {
          errorDialog.value.show()
        } finally {
          reloading.value = false
        }
      }

      return {
        user,
        reloading,
        errorDialog,
        reload
      }
    }
  }
</script>

<style lang='scss' scoped>
  @use 'sass:map';
  @use '@/styles/variables';

  .nja {
    padding: 24px;
    background-color: white;
    border-radius: 4px;
  }

  .prg {
    line-height: 1.75;

    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  .thw {
    font-weight: map.get(variables.$font-weights, 'bold');
  }

  .wor {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }

  .uiq {
    margin-left: 16px;
  }

  @media (min-width: map.get(variables.$breakpoints, 'lg')) {
    .nja {
      padding: 32px;
    }

    .wor {
      margin-top: 32px;
    }
  }
</style>
